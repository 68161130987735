.coupon-container {
  padding: 2rem;
  background-color: #f8f9fa;
}

.coupon-card {
  padding: 1rem;
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
}

.create-coupon-form {
  max-width: 400px;
  margin-top: 1rem;
}
